import * as React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Hero from "../components/Hero";
import Intro from "../components/Intro";
import Seo from "../components/Seo";
import Arrow from "../icons/arrow";
import Quotes from "../components/Quotes";

const ClassesPageTemplate = ({
  hero,
  intro,
  imageText,
  quotes,
}) => {

  const isImageShown =
    imageText.imageToggle === undefined ? false : imageText.imageToggle;
  return (
    <div className="section">
      <Hero
        image={hero.image}
        title={hero.title}
        link={hero.link}
        linkText={hero.linkText}
      />
      <div id="our-classes" className="relative -top-24"></div>
      <Intro
        introImage={intro.introImage}
        title={intro.title}
        text={intro.text}
        link={intro.link}
        linkText={intro.linkText}
        introToggle={intro.introToggle ? intro.introToggle : false}
        theme="bg-white"
      />
      <div className={`bg-[#8FDFBC] md:h-[400px] relative mt-10 md:mt-20`}>
        <div
          className={`md:absolute h-[250px] top-0 w-full md:w-1/2 md:h-full ${
            isImageShown ? "left-0" : "right-0"
          }`}
        >
          <GatsbyImage
            image={getImage(imageText.imageTextImage)}
            className={"w-full h-full"}
            layout="fullWidth"
          />
        </div>

        <div
          className={`mx-auto max-w-screen-xl w-full px-6 h-full flex flex-col justify-center text-white ${
            isImageShown ? " items-end" : ""
          }`}
        >
          <div
            className={`flex flex-col justify-center w-full h-full py-12 md:py-0 md:w-1/2 ${
              isImageShown ? "md:pl-14" : " md:pr-14"
            }`}
          >
            {imageText.title && (
              <h2
                className={`text-xl md:text-2xl font-semibold ${
                  imageText.text || imageText.link
                    ? "mb-4 md:mb-6"
                    : "py-4 md:py-0 text-xl md:text-3xl md:leading-10"
                }`}
              >
                {imageText.title}
              </h2>
            )}
            {imageText.text && (
              <div className={`tc-h2 font-medium`}>{imageText.text}</div>
            )}
            {imageText.link && (
              <Link to={imageText.link} className="flex items-center mt-10">
                <span className={`inline-block mr-8 font-medium`}>
                  {imageText.linkText}
                </span>
                <Arrow color={`#fff`} />
              </Link>
            )}
          </div>
        </div>
      </div>

      <Quotes quotes={quotes} />
    </div>
  );
};

ClassesPageTemplate.propTypes = {
  imageText: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.string,
    linkText: PropTypes.string,
    imageToggle: PropTypes.bool,
  }),
};

const ClassesPage = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter;

  return (
    <Layout>
       <Seo title={frontmatter.hero.title} description={frontmatter.intro.text} />
      <ClassesPageTemplate
        hero={frontmatter.hero}
        intro={frontmatter.intro}
        imageText={frontmatter.imageText}
        quotes={frontmatter.quotes}
      />
    </Layout>
  );
};

ClassesPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const classesPageQuery = graphql`
  query ClassesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        hero {
          title
          link
          linkText
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        intro {
          title
          text
          link
          linkText
          introToggle
          introImage {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        imageText {
          title
          text
          link
          linkText
          imageToggle
          imageTextImage {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        quotes {
          quote
          author
        }
        imageText {
          title
          subtitle
          description
          image {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`;
export default ClassesPage;